// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "vanilla-nested"

require("jquery")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("DOMContentLoaded", function() {
    document.getElementById("corregir").addEventListener("submit", function(event) {
      event.preventDefault(); 
      Swal.fire({
        title: '¿Estás seguro que desea corregir esta gestion?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí, corregir',
        confirmButtonColor: "#8DBD13",
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          event.target.submit();
        }
      });
    });
  });

  document.addEventListener("DOMContentLoaded", function() {
    document.getElementById("realizar").addEventListener("submit", function(event) {
      event.preventDefault(); 
      Swal.fire({
        title: '¿Estás seguro que desea realizar esta gestion?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí, realizar',
        confirmButtonColor: "#8DBD13",
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          event.target.submit();
        }
      });
    });
  });



 